import * as React from 'react';
import { TypeAnimation } from 'react-type-animation';
import './home.scss'
import CurrentDate from '../../components/Date/Date';

class Home extends React.Component<{}, { animate: boolean }> {
  constructor(props:any) {
    super(props);
    this.state = {animate: true};
  }

  componentDidMount(){
    if (window.sessionStorage.getItem("firstLoadDoneHome") === null) {
      this.setState({
        animate: true,
      })
      window.sessionStorage.setItem("firstLoadDoneHome", "1")
    } else {
      this.setState({
        animate: false,
      })
    }
  }

  render () {
  return (
  <div className="home">
    <div className="type">
        <div><CurrentDate></CurrentDate></div>
        {this.state.animate ?
        <TypeAnimation
          style={{ whiteSpace: 'pre-line', height: '195px', display: 'block', float: 'left' }}
          sequence={[
            'Hi, my name is TJ\nWelcome to my site\nI am a\nUI/UX Designer\nFront End Developer\nProject/Program Manager\nDevOps/IT Developer\nClick on the menu in the upper right for more about my work\nAnd check back often as I add content and functionality',
            1000
          ]}
          speed={75}
        /> : 
        <div>
          <div>Hi, my name is TJ</div>
          <div>Welcome to my site</div>
          <div>I am a</div>
          <div>UI/UX Designer</div>
          <div>Front End Developer</div>
          <div>Project/Program Manager</div>
          <div>DevOps/IT Developer</div>
          <div>Click on the menu in the upper right for more about my work</div>
          <div>And check back often as I add content and functionality</div>
        </div> }
    </div>
  </div>
  );
};
}

export { Home };
