import React from 'react';
import './header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuComponent } from '../Menu/Menu';
import ViewSize from '../ViewSize/ViewSize';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

class Header extends React.Component<{}, { animate: boolean }> {

  render() {
    return <div> 
      <header className="App-header">
        <ViewSize />
        <div className="back">
          <a href={'/'}><FontAwesomeIcon icon={faHouse} size="sm" /></a>
        </div>  
        <MenuComponent />
      </header>
    </div>
  }
}

export { Header };
