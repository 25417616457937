import { CaseStudyProp } from "../interfaces/interfaces";
import focushome from '../img/focushome.png';

const Challenges = () =>
    <ol className="case-list">
      <li>Ensuring Accessibility Everywhere</li>
      <li>Adapting to Current and Future Database Requirements</li>
      <li>Balancing Functionality with Minimalist Design</li>
      <li>Empowering Staff to Edit Content</li>
      <li>Providing Easily Accessible and Organized Information for End Users</li>
      <li>Tracking Contacts, Events, and Donations</li>
      <li>Managing Bulk Mailings</li>
    </ol>

const SolutionText = () =>
    <div>
        Upon a comprehensive assessment of FOCUS' requirements, conducted through employee interviews and workflow analyses, several hurdles emerged, including theme compatibility issues, data upload complications, and project delays. To tackle these challenges effectively, I structured the project into three distinct phases:
        <ol className="case-list">
          <li><span className="blue">Theme and Drupal Release:</span> Despite initial design discrepancies, I facilitated constructive dialogue among stakeholders, culminating in a theme that garnered consensus after several iterations. Delegating page refinement tasks to departmental personnel allowed me to concentrate on refining the public-facing website.</li>
          <li><span className="blue">CiviCRM Cleanup:</span> Phase two was dedicated to resolving CRM complexities, streamlining the database, and reconstructing views that were disrupted by routine updates. A pivotal aspect involved harmonizing data visualizations on focusdc.org/data, ensuring alignment with user preferences.</li>
          <li><span className="blue">Donor Data Migration and Customization:</span> The final phase centered on seamlessly migrating donor data into the CRM, necessitating continuous customization and integration efforts to align with evolving FOCUS requirements.</li>
        </ol>
        By meticulously navigating these phases, I successfully addressed FOCUS' multifaceted needs, fostering design cohesion, optimizing data management processes, and enhancing user engagement across the organization's digital ecosystem.
    </div>

const Outcome = () =>
  <div>
    The implementation of a structured approach to address FOCUS' challenges yielded significant positive outcomes. The culmination of efforts resulted in a minimalist website that effectively promotes the organization's mission while meeting the diverse needs of employees and users alike.
    <ol className="case-list">
      <li><span className="blue">Enhanced Accessibility:</span> The website now ensures accessibility across various devices and platforms, catering to a wider audience.</li>
      <li><span className="blue">Adaptability:</span> By leveraging scalable solutions, the website is well-equipped to accommodate current and future database requirements, ensuring seamless operations as the organization grows.</li>
      <li><span className="blue">Unified Design:</span> Through collaborative efforts and iterative design processes, a harmonized theme was achieved, reflecting the organization's identity and values while maintaining a minimalist aesthetic.</li>
      <li><span className="blue">Empowered Staff:</span> Departmental staff were empowered to edit content, fostering a culture of ownership and enabling timely updates to the website.</li>
      <li><span className="blue">User-Friendly Experience:</span> End users benefit from easily attainable and organized information, enhancing their overall experience and engagement with the website.</li>
      <li><span className="blue">Effective Tracking Mechanisms:</span> Robust tracking mechanisms were implemented for contacts, events, and donations, providing valuable insights and facilitating informed decision-making.</li>
      <li><span className="blue">Efficient Communication:</span> The integration of bulk mailing capabilities enables efficient communication with stakeholders, enhancing outreach efforts and fostering community engagement.</li>
    </ol>
  </div>

const Conclusion = () =>
  <div>
    In conclusion, the journey from conceptualization to implementation underscored the importance of collaboration, adaptability, and strategic planning in overcoming complex challenges. By prioritizing user-centric design, streamlined data management, and seamless integration of functionalities, FOCUS successfully transformed its digital presence, aligning it with its organizational goals and aspirations. Moving forward, continued iteration and refinement will be crucial to ensure the website remains responsive to evolving needs and technologies, thereby sustaining its effectiveness in promoting FOCUS' mission and engaging its stakeholders.
  </div>

  const cases: CaseStudyProp[]  = [
    { 
      img: focushome,
      section: 'Opportunity', 
      text: 'How does a growing organization, grappling with divergent design visions and 15 years worth of disparate data, craft a sleek website that authentically showcases its mission while meeting the aesthetic and practical demands of both its team members and users?',
      id: 1
    }, 
    { 
      section: 'Challenges Faced',
      text: <Challenges></Challenges>,
      id: 2
    },
    { 
      section: 'Solution', 
      text: <SolutionText></SolutionText>,
      id: 3
    }, 
    { 
      section: 'Outcome', 
      text: <Outcome></Outcome>,
      id: 5
    },
    { 
      section: 'Conclusion', 
      text: <Conclusion></Conclusion>,
      id: 5
    }
  ];

  export default cases;