import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderClosed } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import './viewsize.scss'

function ViewSize() {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (

      <div className="screen-size">
        <a href={'/'}>
          <FontAwesomeIcon className="folder" icon={faFolderClosed} size="xs" />tj jansma - zsh - {screenSize.width}x{screenSize.height}
        </a>
      </div>
  );
};


export default ViewSize;