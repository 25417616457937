import { CaseStudyProp } from "../interfaces/interfaces";
import dsilogo from '../img/dsilogo.png';

const Opportunity = () =>
  <div>
    <h4>Introduction:</h4>
    In the contemporary business landscape, efficient change management processes are essential for organizations to adapt and thrive. This case study explores how DSI (Digital Solutions Inc.), a Microsoft-centered organization, leveraged its existing technology systems to unify and progress change seamlessly.
    <h4>Background:</h4>
    DSI, a leading provider of digital solutions, recognized the need to streamline its change management processes to enhance efficiency and effectiveness. Analysis of DSI's technology systems revealed that no new software was required to unify and progress change, paving the way for a strategic approach to leveraging existing resources.
  </div>

const Challenges = () =>
  <div>
    <h4>Integration Complexity:</h4> 
    DSI encountered the challenge of integrating disparate technology systems to establish a cohesive change management framework.
    <h4>Ensuring Alignment with Corporate Vision:</h4> 
    Aligning change management processes with the organization's corporate vision posed a significant challenge, requiring a clear and systematic approach.
    <h4>Managing Change Requests Effectively:</h4> With a diverse range of stakeholders and change requests, DSI needed a streamlined process to manage and prioritize change effectively.
  </div>

const SolutionText = () =>
    <div>
      <h4>Three-Tiered Software Solution:</h4>
      <ol className="case-list">
        <li>Microsoft Project served as the platform for distilling corporate vision into actionable plans, ensuring alignment with strategic objectives.</li>
        <li>Microsoft Dynamics CRM facilitated the processing of client change requests, providing a centralized repository for capturing and prioritizing incoming requests.</li>
        <li>Azure DevOps emerged as the central hub for development, testing, and implementation of change requests, ensuring a streamlined workflow from inception to deployment.</li>
      </ol>
    <h4>Integration via API:</h4>
    <ol className="case-list">
        <li>API integration enabled seamless communication and data exchange between Microsoft Project, Dynamics CRM, and Azure DevOps, eliminating silos and promoting collaboration across teams.</li>
        <li>Data synchronization ensured real-time visibility into project status, change requests, and bug tracking, enhancing transparency and accountability.</li>
    </ol>
    <h4>Standardized Change Management Process:</h4>
    <ol className="case-list">
        <li>DSI implemented a standardized change management process within Azure DevOps, encompassing the submission, evaluation, prioritization, and implementation of change requests.</li>
        <li>Clear roles and responsibilities were defined to ensure accountability and streamline decision-making, fostering agility and responsiveness to change.</li>
      </ol>
    </div>

  const Outcome = () =>
    <div>
      The implementation of the integrated technology solution yielded several positive outcomes for DSI:
      <ol className="case-list">
        <li><h4>Enhanced Efficiency:</h4> The streamlined change management process reduced administrative overhead and improved overall efficiency, enabling faster response times to change requests.</li>
        <li><h4>Improved Alignment with Corporate Vision:</h4> By aligning change management processes with corporate vision within Microsoft Project, DSI ensured that change initiatives were in harmony with strategic objectives.</li>
        <li><h4>Enhanced Customer Satisfaction:</h4> The use of Microsoft Dynamics CRM for client change requests facilitated timely communication and resolution, enhancing customer satisfaction and loyalty.</li>
        <li><h4>Greater Visibility and Control:</h4> Azure DevOps served as a centralized platform for tracking and managing change requests, providing stakeholders with real-time visibility and control over the change management lifecycle.</li>
      </ol>
    </div>

  const Conclusion = () =>
    <div>
      By leveraging its existing Microsoft-centered infrastructure and implementing a three-tiered software solution integrated via API, DSI successfully unified and streamlined its change management processes. This case study demonstrates the importance of leveraging existing resources, fostering collaboration, and aligning technology solutions with organizational goals to achieve effective change management in technology-driven organizations.
    </div>

  const cases: CaseStudyProp[]  = [
    { 
      img: dsilogo,
      section: 'Opportunity', 
      text: <Opportunity></Opportunity>,
      id: 1
    }, 
    { 
      section: 'Challenges Faced',
      text: <Challenges></Challenges>,
      id: 2
    },
    { 
      section: 'Solution', 
      text: <SolutionText></SolutionText>,
      id: 3
    }, 
    { 
      section: 'Outcome', 
      text: <Outcome></Outcome>,
      id: 4
    },
    { 
      section: 'Conclusion', 
      text: <Conclusion></Conclusion>,
      id: 5
    }
  ];

  export default cases;