import React from "react";
// import doyen from '../../img/doyen.png'
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <div> 
    {/* <div className=""><a href="/"><img alt="doyen" id="do" src={doyen} width="200px" /></a></div> */}
    <button onClick={() => loginWithRedirect()}>Log In</button>;
  </div>
  }

export { LoginButton };
