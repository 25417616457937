import * as React from 'react'
import './about.scss'
import profilepic from '../../img/profilepic.jpg'
import { Component } from 'react';

class About extends Component {
  render = () => {
    return (
      <div className="about">
        {/* <div className="about-hd">About */}
          <div className="wrapper">
            <a href="/docs/resume.pdf" target="_blank"><img alt="Profile Pic" className="profile-pic" src={profilepic} />
            <div className="overlay"></div>
            <div className="tooltip">Click to download my resume</div>
            </a>
          </div>
        {/* </div> */}
        <div>
          
          <div className="about-text">
            <div>I am a seasoned professional with a dual expertise in UI/UX design and Project/Program Management. With a proven track record as a Senior UI/UX Software Engineer, I specialize in crafting intuitive and visually appealing user experiences. My proficiency in Agile methodologies has allowed me to successfully lead cross-functional teams in delivering strategic solutions.</div> &nbsp;
            <div>My portfolio reflects a commitment to marrying design excellence with effective project management, resulting in impactful and user-centric solutions.</div>
          </div>
        </div>
      </div> 
    );
  }
}

export default About;
