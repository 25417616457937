import * as React from 'react';
import { Component } from 'react';
import './notfound.scss'

class NotFound extends Component {

  render = () => {
  return <div className="notfound text-wrapper">
      <div className="text">No Page Found for this URL</div>
    </div>;
  };
}
export default NotFound;

