import React from 'react';
import './menu.scss'
import { slide as Menu } from 'react-burger-menu'

class MenuComponent extends React.Component {
  showSettings (event: { preventDefault: () => void; }) {
    event.preventDefault();
  }

  render () {
    return (
      <div className="menu-container">
        <Menu right className="menu-right">
            <div className="case-title case">case studies</div>
            <div><a id="dsi" className="menu-item" href="/dsi">dsi change process</a></div>
            <div><a id="acm" className="menu-item" href="/acm">dsi acm</a></div>
            <div><a id="uscis" className="menu-item" href="/uscis">uscis</a></div>
            <div><a id="focus" className="menu-item" href="/focus">focus</a></div>
            <div><a id="other" className="menu-item" href="https://legacy.tjjansma.com" target="_blank" rel="noreferrer">other work</a></div>
            <div className="personal">
              <div id="about"><a href="/about">about</a></div>
              <div id="contact"><a href="/contact">contact</a></div>
              <div id="resume"><a href="/docs/resume.pdf" target="_blank" rel="noreferrer">resume</a></div>
            </div>
        </Menu>
      </div>
    );
  }
}

export { MenuComponent };
