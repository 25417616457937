import * as React from 'react'
import './contact.scss'
import { Component } from 'react';

class Contact extends Component {
  render = () => {
    return (
      <div className="contact">
        <div className="contact-hd">Let's Chat!</div>
        <div className="contact-text">Do you want to build something? Feel free to reach out at:</div>
        <a href="mailto: tj@tjjansma.com">tj@tjjansma.com</a>
      </div> 
    );
  }
}

export default Contact;
