import React, { Component } from 'react';
import './dsi.scss'
import cases from '../../data/DsiData';

const Case = (CaseStudyProp: any) => 
    <div className="case-section" key={CaseStudyProp.id}>
      <div className="logo">
      {CaseStudyProp.img != null ? <img alt="DSI Logo" id="dsilogo" src={CaseStudyProp.img} /> : ''}
      </div>
        <div className="case-hd">{CaseStudyProp.section}</div>
        <div className="case-text">{CaseStudyProp.text}</div>
    </div>

class Dsi extends Component {
render() { 
  return (
    <div className="case-container">
      {cases.map(Case)}
    </div>
    );
  }
}

export default Dsi;