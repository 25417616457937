function CurrentDate() {
const currDate = new Date().toLocaleDateString();
const currTime = new Date().toLocaleTimeString();

  return (
    <div>
      <div>Last login: {currDate} {currTime} on console</div>
    </div>
  );
}

export default CurrentDate;