import * as React from 'react'
import './uspto.scss'
import usptologo from '../../img/usptologo.png'

const Uspto = () => {
  return <div className="main">
  <div className="left-main">
    <h1>USPTO</h1>
    <div className="main-text">
        Coming Soon...
    </div>
  </div>
  <div className="right-main">
    <div className="homelogo" id="uspto">
      <div className=""><a href="/"><img alt="uspto" id="uspto" src={usptologo} width="200px" /></a></div>
    </div>
  </div>
</div>
};

export default Uspto;
