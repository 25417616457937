import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from './components/Header/Header';
import { Home } from "./pages/Home";
import { Dsi } from "./pages/Dsi";
import { Acm } from './pages/Acm';
import { Uspto } from "./pages/Uspto";
import { Sec } from "./pages/Sec";
import { Uscis } from "./pages/Uscis";
import { Focus } from "./pages/Focus";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { NotFound }from "./pages/NotFound";
import { Doyen } from './pages/Doyen';

export default function App() {
  return (
    <div className="App">
      <Header></Header>
      <div className="App-main">
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="dsi" element={<Dsi />}/>
                <Route path="doyen" element={<Doyen />}/>
                <Route path="acm" element={<Acm />}/>
                <Route path="uspto" element={<Uspto />}/>
                <Route path="sec" element={<Sec />}/>
                <Route path="uscis" element={<Uscis />}/>
                <Route path="focus" element={<Focus />}/>
                <Route path="about" element={<About />}/>
                <Route path="contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter> 
      </div>
    </div>
  );
}