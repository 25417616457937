import * as React from 'react'
import './sec.scss'
import seclogo from '../../img/seclogo.png'

const Sec = () => {
  return <div className="main">
  <div className="left-main">
    <h1>SEC</h1>
    <div className="main-text">
        Coming Soon...
    </div>
  </div>
    <div className="homelogo" id="sec">
      <div className=""><a href="/"><img alt="sec" id="sec" src={seclogo} /></a></div>
    </div>
  </div>
};

export default Sec;
