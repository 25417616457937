import { CaseStudyProp } from "../interfaces/interfaces";
import uscislogo from '../img/uscislogo.png';

const Opportunity = () =>
<div>
  <h4>Introduction:</h4>
  The focus of this case study is to evaluate the modernization of the USCIS NASS Scheduling Application.

  <h4>Background:</h4>
  USCIS was facing the challenge of modernizing its scheduling application built on AngularJS while ensuring zero downtime and maintaining a standardized design across all pages. Additionally, the agency aimed to leverage the benefits of React.js for improved performance and maintainability.
</div>


const Challenges = () =>
  <div>
    <ol className="case-list">
      <li>Migration from AngularJS to React.js without disrupting the application's availability.</li>
      <li>Ensuring consistency in design across the application.</li>
      <li>Integrating the USWDS design framework seamlessly into the application.</li>
    </ol>
  </div>

const SolutionText = () =>
  <div>
    <p>The migration strategy adopted by the team involved a phased approach, utilizing routing props to synchronously run a React.js/node.js based server alongside the existing AngularJS legacy server. This allowed for the conversion of pages individually, ensuring minimal disruption to the application's availability.</p>
    <p>To address the design consistency challenge, the team integrated the USWDS (U.S. Web Design System) design framework into the base styling microservice. By doing so, they could leverage the design framework uniformly across all pages of the application. This integration facilitated a standardized and cohesive user experience, aligning with modern design principles and accessibility standards.</p>
    <ol className="case-list">
      <li><span className="blue">Infrastructure Setup:</span> The team established a React.js/node.js server alongside the existing AngularJS server, utilizing routing props for seamless page conversion.</li>
      <li><span className="blue">Phase 2: Migration Process:</span> Pages were migrated from AngularJS to React.js incrementally, with each page undergoing thorough testing to ensure functionality and compatibility.</li>
      <li><span className="blue">Phase 3: Integration of USWDS:</span> The USWDS design framework was integrated into the base styling microservice, enabling consistent application of design elements across all pages.</li>
      <li><span className="blue">Testing and Validation:</span> Comprehensive testing was conducted at each phase of the migration process to validate functionality, performance, and design consistency.</li>
      <li><span className="blue">Deployment:</span> The migrated application, now running on React.js with USWDS design elements, was deployed gradually, ensuring zero downtime and a seamless transition for end-users.</li>
    </ol>
  </div>

const Outcome = () =>
  <div>
    <ol className="case-list">
      <li><h4>Zero Downtime Migration:</h4> The migration from AngularJS to React.js was executed seamlessly, with no disruption to the application's availability.</li>
      <li><h4>Improved Performance and Maintainability:</h4> The adoption of React.js resulted in enhanced performance and maintainability of the application, offering a more responsive and scalable user experience.</li>
      <li><h4>Consistent Design:</h4> By integrating the USWDS design framework, the application achieved a consistent and standardized design across all pages, enhancing usability and accessibility.</li>
    </ol>
  </div>

const Conclusion = () =>
  <div>
The successful migration from AngularJS to React.js with zero downtime, coupled with the integration of the USWDS design framework, exemplifies the effectiveness of a well-planned and executed modernization strategy. By leveraging routing props for phased migration and incorporating design standards through USWDS, USCIS achieved its objectives of improving application performance, maintainability, and user experience while ensuring uninterrupted service for its stakeholders.
  </div>
  
  const cases: CaseStudyProp[]  = [
    { 
      img: uscislogo,
      section: 'Opportunity', 
      text: <Opportunity></Opportunity>,
      id: 1
    }, 
    { 
      section: 'Challenges Faced',
      text: <Challenges></Challenges>,
      id: 2
    },
    { 
      section: 'Solution', 
      text: <SolutionText></SolutionText>,
      id: 3
    }, 
    { 
      section: 'Outcome', 
      text: <Outcome></Outcome>,
      id: 4
    },
    { 
      section: 'Conclusion', 
      text: <Conclusion></Conclusion>,
      id: 5
    }
  ];

  export default cases;