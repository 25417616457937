import { CaseStudyProp } from "../interfaces/interfaces";
import dsilogo from '../img/dsilogo.png';

const Opportunity = () =>
  <div>
    <h4>Introduction:</h4>
    In the realm of software development, crafting a standardized framework that aligns with stakeholder requirements while adhering to executive guidance is imperative for project success. This case study explores how the AEON Configuration Management (ACM) team successfully developed such a framework, striking a balance between stakeholder needs and executive directives.

    <h4>Background:</h4>
    The ACM team embarked on a mission to establish a standardized development framework to streamline their processes and enhance overall efficiency. Recognizing the importance of incorporating stakeholder requirements and executive guidance, the team aimed to create a framework that would leverage Microsoft Azure infrastructure, a C# backend, and an open-source TypeScript Vue library framework for the front end.
  </div>

const Challenges = () =>
  <div>
    <ol className="case-list">
    <li><span className="blue">Integrating diverse stakeholder requirements:</span> The ACM team encountered the challenge of reconciling various stakeholder demands while ensuring that the framework remains cohesive and effective.</li>
    <li><span className="blue">Aligning with executive directives:</span> Balancing stakeholder needs with executive guidance posed a significant challenge, as the team aimed to meet both sets of expectations without compromising on quality or efficiency.</li>
    </ol>
  </div>

const Solution = () =>
    <div>
      <p>To address these challenges, the ACM team adopted a systematic approach that encompassed the following key steps:</p>
      <h4>Comprehensive Stakeholder Engagement and Alignment with Executive Guidance:</h4>
      <ol className="case-list">
          <li>The team conducted and meticulously analyzed consultations with stakeholders from different departments to understand their unique requirements and expectations.</li>
          <li>Close collaboration with executives helped the team gain insights into overarching organizational goals and strategic directives.</li>
          <li>Regular communication through a feedback loop ensured that the framework's development remained aligned with executive vision while accommodating stakeholder needs.</li>
      </ol>

      <h4>Technological Integration:</h4>
      <ol className="case-list">
          <li>Leveraging Microsoft Azure provided a robust and scalable infrastructure that met both stakeholder and executive requirements for reliability and performance.</li>
          <li>Implementing a C# backend facilitated seamless integration with existing systems and enhanced interoperability.</li>
          <li>The adoption of an open-source TypeScript Vue library framework for the front end ensured flexibility, customization options, and community support.</li>
          <li>The ACM team adopted an iterative development approach, allowing for continuous refinement based on stakeholder feedback and evolving executive directives.</li>
      </ol>
    </div>

const Outcome = () =>
  <div>
    The implementation of the standardized development framework yielded several positive outcomes:
    <ol className="case-list">
      <li><span className="blue">Enhanced Efficiency:</span> The streamlined framework led to increased development efficiency, reducing time-to-market for new products and features.</li>
      <li><span className="blue">Improved Stakeholder Satisfaction:</span> By incorporating stakeholder requirements into the framework, the ACM team bolstered stakeholder satisfaction and fostered a sense of ownership among key stakeholders.</li>
      <li><span className="blue">Strategic Alignment:</span> The framework successfully aligned with executive guidance, ensuring that development efforts remained in sync with organizational objectives.</li>
      <li><span className="blue">Future Scalability:</span> Leveraging Microsoft Azure and open-source technologies positioned the framework for future scalability, allowing for seamless expansion and adaptation to evolving needs.</li>
    </ol>
  </div>

const Conclusion = () =>
  <div>
  The ACM team's endeavor to create a standardized development framework exemplifies the importance of balancing stakeholder requirements with executive guidance. Through meticulous planning, technological integration, and iterative refinement, the team successfully crafted a framework that not only met the diverse needs of stakeholders but also aligned with the strategic vision of the organization. This case study underscores the significance of collaboration, adaptability, and innovation in achieving success in software development initiatives.
  </div>
  
  const cases: CaseStudyProp[]  = [
    { 
      img: dsilogo,
      section: 'Opportunity', 
      text: <Opportunity></Opportunity>,
      id: 1
    }, 
    { 
      section: 'Challenges Faced',
      text: <Challenges></Challenges>,
      id: 2
    },
    { 
      section: 'Solution', 
      text: <Solution></Solution>,
      id: 3
    }, 
    { 
      section: 'Outcome', 
      text: <Outcome></Outcome>,
      id: 4
    },
    { 
      section: 'Conclusion', 
      text: <Conclusion></Conclusion>,
      id: 5
    }
  ];

  export default cases;