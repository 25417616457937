import React, { Component } from 'react';
import './doyen.scss'
import { LoginButton } from '../../components/Login/Login';

class Doyen extends Component {
render() { 
  return (
    <div className="doyen-container">
      <LoginButton></LoginButton>
    </div>
    );
  }
}

export default Doyen;